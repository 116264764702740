<template>
  <div>
    <!-- 搜索区域 -->
    <div class="search-area">
      <el-form
        ref="form"
        :model="queryParams"
        inline
        @keyup.enter.native="onSearch"
      >
        <el-form-item label="商户名称">
          <el-input v-model="queryParams.customerName"></el-input>
        </el-form-item>
        <el-form-item label="分配状态">
          <el-select v-model="queryParams.isAssign">
            <el-option label="全部" :value="-1"></el-option>
            <el-option label="未分配" :value="0"></el-option>
            <el-option label="已分配" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号">
          <el-select v-model="queryParams.userName">
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="(item, key) in userList"
              :label="item"
              :value="item"
              :key="key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch"
            >查询</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh-right"
            @click="onReset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table
      v-loading="loading"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      :data="tableData"
      border
      style="width: 100%"
    >
      <el-table-column
        align="center"
        prop="CustomerName"
        label="商户名称"
        fixed
      ></el-table-column>
      <el-table-column
        align="center"
        prop="userName"
        label="所属销售账号"
        fixed
      ></el-table-column>
      <el-table-column
        align="center"
        prop="CustomerID"
        label="商户ID"
        fixed
      ></el-table-column>
      <el-table-column align="center" label="操作" width="100">
        <template slot-scope="scope">
          <!-- <el-button type="text" size="small"
            >分配</el-button
          > -->
          <el-tooltip
            v-if="!scope.row.userName"
            class="item"
            effect="dark"
            content="分配"
            placement="top"
          >
            <Icon
              class="svg"
              iconClass="user-branch"
              @click="onBranch(scope.row)"
            ></Icon>
          </el-tooltip>
          <el-tooltip
            v-if="scope.row.userName"
            class="item"
            effect="dark"
            content="删除"
            placement="top"
          >
            <i
              @click="onRemove(scope.row)"
              class="delete-icon icon el-icon-delete"
            ></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="jumper, prev, pager, next, sizes, total"
        :total="pagination.total"
        @current-change="onPageChange"
        @size-change="onPageSizeChange"
        :current-page="queryParams.page"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="分配"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleDialogClose"
    >
      <el-form ref="dialogFormRef" :model="dialogModel" :rules="dialogRules">
        <el-form-item label="销售账号" prop="actionUserName">
          <el-select v-model="dialogModel.actionUserName">
            <el-option
              v-for="(item, key) in userList"
              :label="item"
              :value="item"
              :key="key"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="handleDialogClose">取 消</el-button>
        <el-button type="primary" @click="onSetMerchantBranch">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getMerchantRelationList, getAccountList, deleteMerchantBranch, saveMerchantBranch } from '@/api/api'
export default {
  data () {
    return {
      queryParams: { isAssign: -1, page: 1, size: 10 },
      loading: false,
      tableData: [],
      userList: [],
      pagination: {},
      dialogVisible: false,
      actionCustomerId: null,
      dialogModel: {},
      dialogRules: {
        actionUserName: [{ required: true, message: '请选择销售账号' }]
      },
    }
  },
  mounted () {
    this.loadData()
    this.getAccountList()
  },
  methods: {
    onSetMerchantBranch () {
      this.$refs.dialogFormRef.validate(valid => {
        if (!valid) { return false }
        saveMerchantBranch({
          customerID: this.actionCustomerId,
          userName: this.dialogModel.actionUserName,
        }).then(res => {
          if (res.code === 0) {
            this.$message.success('操作成功')
            this.loadData()
            this.handleDialogClose()
          }
        })
      })
    },
    handleDialogClose () {
      this.dialogVisible = false
      this.dialogModel = {}
      this.actionCustomerId = null
    },
    onPageChange (e) {
      this.queryParams.page = e
      this.loadData()
    },
    onPageSizeChange (e) {
      this.queryParams.size = e
      this.loadData()
    },
    getAccountList () {
      getAccountList().then(res => {
        if (res.code === 0 && Array.isArray(res.data)) {
          this.userList = res.data
        }
      })
    },
    onRemove (record) {
      this.$messageBox.confirm('此操作将从该商户下移除账号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteMerchantBranch({
          customerID: record.CustomerID,
          userName: record.userName,
        }).then(res => {
          if (res.code === 0) {
            this.$message.success('移除成功')
            this.loadData()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    onSearch () {
      this.queryParams.page = 1
      this.loadData()
    },
    onBranch (record) {
      this.dialogVisible = true
      this.actionCustomerId = record.CustomerID
      console.log('🚀 ~ file: MerchantBranch.vue ~ line 74 ~ onBranch ~ record', record)
    },
    onReset () {
      Object.keys(this.queryParams).forEach(key => {
        if (key !== 'page' && key !== 'size') {
          this.queryParams[key] = ''
        }
      })
      this.dateTime = ''
      this.queryParams.page = 1
      this.queryParams.isAssign = -1
      this.loadData()
    },
    loadData () {
      this.loading = true
      getMerchantRelationList(this.queryParams).then(res => {
        this.loading = false
        if (res.code === 0) {
          this.tableData = res.data
          this.pagination = {
            total: parseInt(res.total),
            size: Math.floor(parseInt(res.total) / this.queryParams.size)
          }
        } else {
          this.tableData = []
          this.$message.error(res.msg)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/search.scss';
.delete-icon {
  cursor: pointer;
  color: #409eff;
  vertical-align: middle;
  font-size: 16px;
}
.svg {
  cursor: pointer;
  color: #409eff;
  font-size: 18px;
}
.pagination {
  margin-top: 20px;
  padding-bottom: 30px;
  float: right;
}
</style>
